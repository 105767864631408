import type { ActionFunctionArgs, MetaFunction } from "@remix-run/node";
import { authenticator } from "../.server/services/authentication";
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import { Form, useNavigation } from "@remix-run/react";

import React from "react";

import { Input } from "../components/base/input";
import { Field, Label } from "../components/base/fieldset";
import { Button } from "../components/base/button";
import { getPageTitle } from "../operations/getPageTitle";
import { checkIsValidPhoneNumber } from "../operations/checkIsValidPhoneNumber";
import Spinner from "../components/spinner";
import { SubmitButton } from "../components/submitButton";

export const meta: MetaFunction = () => {
  return [{ title: getPageTitle("Login") }];
};

export let action = async ({ request }: ActionFunctionArgs) => {
  const url = new URL(request.url);
  const method = url.searchParams.has("usingSMS") ? "twilio" : "TOTP";
  // The success redirect is required in this action, this is where the user is
  // going to be redirected after the magic link is sent, note that here the
  // user is not yet authenticated, so you can't send it to a private page.
  await authenticator.authenticate(method, request, {
    successRedirect: "/auth/login/complete",
    // If this is not set, any error will be throw and the ErrorBoundary will be
    // rendered.
    failureRedirect: "/auth/login/complete",
  });
};

export default function LoginIndex() {
  const [usingSMS, setUsingSMS] = React.useState(false);
  const searchParams = new URLSearchParams({ index: "" });

  if (usingSMS) {
    searchParams.set("usingSMS", "");
  }

  return (
    <Form
      className="space-y-6"
      action={`/auth/login?${searchParams.toString()}`}
      method="post"
      noValidate={usingSMS}
    >
      <Field>
        <Label>Enter your E-mail address or phone number.</Label>
        <Input
          type={usingSMS ? "tel" : "email"}
          name={usingSMS ? "phone" : "email"}
          required
          autoFocus
          placeholder=""
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setUsingSMS(checkIsValidPhoneNumber(newValue));
          }}
        />
      </Field>
      <SubmitButton
        className="w-full"
        busyContent={"Getting Code..."}
        idleIcon={usingSMS ? <DevicePhoneMobileIcon /> : <EnvelopeIcon />}
        idleContent={"Get Code"}
      />
    </Form>
  );
}
